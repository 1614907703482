import React, {Component} from 'react';
import "../../sass/common.scss";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import Moment from 'react-moment';
import {
    getContestsTitle, getOopsTitle,
    getOptInThankYou, getPaymentThankYouTitle,
    getPaymentTitle,
    getResultsTitle,
    ModalEntry
} from "../../pages/LandingPage";
import Checkout from "../misc/Checkout";
import SubmissionResults from "../misc/SubmissionResults";
import {
    DbmodelsSubmission, DbmodelsUser,
    ResponsesSubmissionsResponse
} from "../../api_clients/as_client/src";
import OptInForm from "../misc/OptInForm";
import {FaEdit} from "react-icons/fa";
import {RouteComponentProps, withRouter} from "react-router";
import {toast} from "react-toastify";

interface UserSubmissionsViewBaseProps {
    openModal: (modal:ModalEntry)=>void;
    user: DbmodelsUser;
    loadUser: ()=>void;
}

interface UserSubmissionsViewState{
    submissions: DbmodelsSubmission[];
}

type UserSubmissionsViewProps = UserSubmissionsViewBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps;

class UserSubmissionsView extends Component<UserSubmissionsViewProps,UserSubmissionsViewState> {
    state={
        submissions: [] as DbmodelsSubmission[]
    };
    componentDidMount(): void {
        this.loadSubmissions(()=>{
            this.loadTrackFromPram();
        });
    }

    loadSubmissions = (done?:()=>void) =>{
        this.props.handleRequest(asApiClient.usersApi.getSubmissions(this.props.systemState.token),(response:AxiosResponse<ResponsesSubmissionsResponse>)=>{
            if(response.data.data.submissions!==null && response.data.data.submissions!==undefined) {
                this.setState({
                    submissions: response.data.data.submissions
                },done);
            }
        });
    };

    loadTrackFromPram = () =>{
        let trackParam = this.getTrackParam();
        if(trackParam!==null){
            for (let i = 0; i < this.state.submissions.length; i++) {
                if(this.state.submissions[i].unid===trackParam){
                    this.openModal(i);
                    this.props.history.push(window.location.pathname);
                    return;
                }
            }
            toast.error("Error. Looks like the link is not valid with your account. Please login with the account associated with this link.",{position: toast.POSITION.TOP_CENTER});
        }
    };

    getTrackParam = () =>{
        const urlParams = new URLSearchParams(document.location.search);
        let track = urlParams.get('track');
        if(track!==null && track.length>0){
            return track;
        }
        return null;
    };

    onOptInComplete = (afterPayment:boolean) =>{
        this.loadSubmissions();
        this.props.loadUser();
        if(afterPayment) {
            this.props.openModal({
                title: getPaymentThankYouTitle(),
                body: (
                    <div>
                    </div>
                )
            });
        }else{
            this.props.openModal({
                title: getOptInThankYou(),
                body: (
                    <div>
                    </div>
                )
            });
        }
    };

    onPaymentComplete = (index:number) =>{
        this.loadSubmissions();
        let submission = this.state.submissions[index];
        this.props.openModal({
            title: getContestsTitle(),
            body: <OptInForm onSuccess={()=>this.onOptInComplete(true)} submissionUnid={submission.unid as string} user={this.props.user}/>
        });
    };

    openModal = (index:number) =>{
        let submission = this.state.submissions[index];
        if(submission.status===0){
            this.props.openModal({
                title: getPaymentTitle(submission.title),
                body: <Checkout onComplete={()=>this.onPaymentComplete(index)} submissionUnid={submission.unid as string}/>
            });
        }else{
            this.props.openModal({
                title: getResultsTitle(),
                body: <SubmissionResults submissionUnid={submission.unid as string}/>
            });
        }
    };

    onOptInEdit = (e:any,index:number) =>{
        e.stopPropagation();
        let submission = this.state.submissions[index];
        this.props.openModal({
            title: getContestsTitle(),
            body: <OptInForm onSuccess={()=>this.onOptInComplete(false)} submissionUnid={submission.unid as string} user={this.props.user}/>
        });
    };

    render() {
        return (
            <div className={"user-submissions"}>
                <div className={"wn-list user-submissions"}>
                    {this.state.submissions.map((submission:DbmodelsSubmission,index:number)=>{
                        let status = null;
                        if(submission.status===0){
                            status = "Tap to Submit";
                        }
                        else if(submission.status===1){
                            status = "Gathering Feedback";
                        }else if(submission.status===2){
                            status = "Complete";
                        }
                        let contests = (<span className={"optin-text-edit"} onClick={(e:any)=>this.onOptInEdit(e,index)}>Contests: No <span className={"optin-edit"}><FaEdit/></span></span>);
                        if(submission.optInContests){
                            contests = (<span>Contests: Yes</span>);
                        }
                        return(
                            <div className={"item"} key={"submission-"+index} onClick={()=>this.openModal(index)}>
                                <div className={"title"}>{submission.title}</div>
                                <div className={"create-date"}>{<Moment local format="MM/DD/YYYY hh:mm">{submission.createTime}</Moment>}</div>
                                <div className={"status"}>{status}</div>
                                <div className={"in-contests"}>{contests}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withSystemState(withApiHandler(withRouter(UserSubmissionsView),ErrorHandler.TOAST));