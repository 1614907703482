import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {Formik} from "formik";
import * as yup from 'yup';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import ReactPlayer from "react-player";
import '../../sass/components/SubmitTrackForm.scss';
import * as _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";
import {DbmodelsSubmission, ResponsesSubmissionResponse} from "../../api_clients/as_client/src";
import ReactPixel from "react-facebook-pixel";
import {_learnq} from "../../index";
import config from "../../config/config";

interface SubmitTrackFormBaseProps{
    onSuccess: (submission:DbmodelsSubmission)=>void;
}

type SubmitTrackFormProps = SubmitTrackFormBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

interface SubmitTrackFormState{
    link: string;
    validLink: boolean;
    submitting: boolean;
    linkErr: boolean;
    linkLoaded: boolean;
}

const submitTrackSchema = yup.object({
    title: yup.string().required(),
    link: yup.string().required()
});

class SubmitTrackForm extends React.Component<SubmitTrackFormProps, SubmitTrackFormState> {
    state={
        link: "",
        validLink:false,
        submitting: false,
        linkErr: false,
        linkLoaded: false,
    };

    submit = _.throttle((formData:any)=>{
        if(this.state.submitting){
            return;
        }
        this.setState({submitting:true});
        let data = {...formData};
        data["link"] = this.state.link;
        data["source"] = "youtube";
        this.props.handleRequest(asApiClient.usersApi.createSubmission(this.props.systemState.token,data),(response:AxiosResponse<ResponsesSubmissionResponse>)=>{
            this.props.onSuccess(response.data.data.submission);
            ReactPixel.trackCustom("Page View","/submission");
            ReactGA.event({
                category: config.googleAnalytics.events.submitTrackDetails.category,
                action: config.googleAnalytics.events.submitTrackDetails.action,
            });
            ReactPixel.trackCustom(config.fb.events.submitTrackDetails,null);
            _learnq.push(['track', 'event', {"id":config.klaviyo.events.submitTrackDetails}]);
            // _hsq.push(["trackEvent", {
            //     id: config.hubSpot.events.submitStep2
            // }]);
        },undefined,()=>{
            this.setState({submitting:false})
            this.props.updateSubmission({})
        });
    },2000,{leading:true,trailing:false});

    getPreview = () =>{
        let link = this.state.link;
        if(this.state.validLink) {
            return (
                <div className={'player-wrapper ' + (this.state.validLink ? "valid-link" : "")}>
                    <ReactPlayer
                        className='react-player'
                        url={link}
                        width='100%'
                        height='100%'
                        controls={true}
                        playing onError={() => {this.setState({linkErr: true});console.log("play error")}}
                        onReady={() => {this.setState({linkLoaded: true});console.log("play ready")}}/>
                </div>
            );
        }
    };

    onCustomHandleChange = (e:any, formikChangeHandler:any) =>{
        if(e.target.id === "validationLink"){
            let link = e.target.value;
            if(link.includes("m.soundcloud.com")){
                link = link.replace("m.soundcloud.com","soundcloud.com")
            }
            let canPlay = ReactPlayer.canPlay(link);
            let validUrl = this.validURL(link);
            let valid = validUrl && canPlay;
            this.setState({
                link: link,
                validLink:valid,
                linkErr: false,
                linkLoaded: false
            });
        }
        formikChangeHandler(e);
    };

    validURL = (str:string) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    };

    render(){
        return(
            <div className={"submit-track-form wn-form"}>
            <Formik
                validationSchema={submitTrackSchema}
                onSubmit={this.submit}
                validateOnChange={true}
                initialValues={{
                    title:'',
                    link:''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                    //   isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} xs="12" controlId="validationTitle">
                                <Form.Label className={"sr-only"}>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder={"title"}
                                    value={values.title}
                                    onChange={(e:any)=>this.onCustomHandleChange(e,handleChange)}
                                    isInvalid={!!errors.title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs="12" controlId="validationLink">
                                <Form.Label className={"sr-only"}>Link</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="link"
                                    placeholder={"link"}
                                    value={values.link}
                                    onChange={(e:any)=>this.onCustomHandleChange(e,handleChange)}
                                    isInvalid={!!errors.link}
                                />
                                <Form.Text className="text-muted">
                                    Soundcloud or YouTube links only
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    {errors.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {this.getPreview()}
                        <div>
                            <Button disabled={!this.state.validLink || this.state.submitting || this.state.linkErr || !this.state.linkLoaded} variant="primary" size="lg" block onClick={()=>handleSubmit()}>{!this.state.submitting?<span>Continue</span>: <><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Submitting...</>}</Button>
                        </div>
                        <div className={"text-link"}>Need Help? <a href="https://www.cognitoforms.com/MediaCo1/WhosNextHelpDesk" target="_blank">Contact Us</a></div>
                    </Form>
                )}
            </Formik>

            </div>
        );
    }
}

export default withSystemState(withApiHandler(SubmitTrackForm,ErrorHandler.TOAST));