import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {AxiosResponse} from "axios";
import {Formik} from "formik";
import * as yup from 'yup';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import FacebookLogin, {ReactFacebookLoginInfo} from 'react-facebook-login';
import '../../sass/common.scss';
import '../../sass/components/LoginForm.scss';
import config from "../../config/config";
import { FaFacebook} from 'react-icons/fa';
import {
    ResponsesBaseResponse,
    ResponsesLoginResponse,
    ResponsesUserProfileResponse
} from "../../api_clients/as_client/src";
import ReactPixel from "react-facebook-pixel";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {_learnq} from "../../index";
import ReactGA from "react-ga";
import {toast} from "react-toastify";
import axios from 'axios';

interface LoginFormBaseProps{
    onSuccess: (response: AxiosResponse<ResponsesLoginResponse>)=>void;
}

type LoginFormProps = LoginFormBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

interface LoginFormState{
    email: string;
    password: string;
    submitType: string;
    invalidPhone:boolean;
}

const loginSchema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
});

const forgotSchema = yup.object({
    email: yup.string().required(),
});

const registerSchema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(6),
    name: yup.string().required(),
    phoneNumber: yup.string(),
    acceptsMarketing: yup.bool()
});

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    state={
        email: "",
        password: "",
        invalidPhone: false,
        submitType: "login"
    };

    submit = (formData:any) =>{
        if(this.state.submitType==="login"){
            this.props.handleRequest(asApiClient.accountsApi.login(formData),(response:AxiosResponse<ResponsesLoginResponse>)=>{
                ReactGA.event({
                    category: config.googleAnalytics.events.login.category,
                    action: config.googleAnalytics.events.login.action,
                });
                ReactPixel.trackCustom(config.fb.events.login,null);
                _learnq.push(['track', 'event', {"id":config.klaviyo.events.login}]);
                // _hsq.push(["trackEvent", {
                //     id: config.hubSpot.events.login
                // }]);
                let utmParams = localStorage.getItem("utmParams");
                if(utmParams){
                    asApiClient.usersApi.trackUTM(response.data.data.token,{utmParams:utmParams,url:window.location.href,action: "login"}).then((response:AxiosResponse<ResponsesBaseResponse>)=> {

                    }).catch((error:any)=> {
                        console.log("could not track utm",error);
                    });
                }
                this.handleFBOnlyLoginEvent(response.data.data.token);
                this.handleLoginResponse(response)
            })
        }else if (this.state.submitType==="signup"){
            let phone = formData.phoneNumber;
            if(phone!==null && phone !==undefined && phone.length>0){
                if(!isValidPhoneNumber(phone) || phone.length==1){
                    this.setState({invalidPhone:true});
                    toast.error("Invalid phone number. Please check your country's flag!", {position: toast.POSITION.TOP_CENTER});
                    return;
                }
            }else{
                delete formData.phoneNumber;
            }
            this.props.handleRequest(asApiClient.accountsApi.signUp(formData),(response:AxiosResponse<ResponsesLoginResponse>)=>{
                ReactGA.event({
                    category: config.googleAnalytics.events.register.category,
                    action: config.googleAnalytics.events.register.action,
                });
                ReactPixel.trackCustom(config.fb.events.register,null);
                _learnq.push(['track', 'event', {"id":config.klaviyo.events.register}]);
                // _hsq.push(["trackEvent", {
                //     id: config.hubSpot.events.signup
                // }]);
                let utmParams = localStorage.getItem("utmParams");
                if(utmParams){
                    asApiClient.usersApi.trackUTM(response.data.data.token,{utmParams:utmParams,url:window.location.href,action: "register"}).then((response:AxiosResponse<ResponsesBaseResponse>)=> {

                    }).catch((error:any)=> {
                        console.log("could not track utm",error);
                    });
                }
                this.handleLoginResponse(response);
            })
        }else if(this.state.submitType==="forgot"){
            this.props.handleRequest(asApiClient.accountsApi.forgotPassword(formData),(response:AxiosResponse<ResponsesBaseResponse>)=>{
                this.setState({submitType:"forgotEnd"})
            })
        }
    };

    handleFBOnlyLoginEvent = (token:string) =>{
        this.props.handleRequest(asApiClient.usersApi.getUserProfile(token),(response:AxiosResponse<ResponsesUserProfileResponse>)=>{
            if(response.data.data.curatorPoints>0){
                ReactPixel.trackCustom(config.fb.events.loginCurator,null);
            }
            if(response.data.data.submissions>0){
                ReactPixel.trackCustom(config.fb.events.loginArtist,null);
            }
            if(response.data.data.curatorPoints>0 && response.data.data.submissions>0){
                ReactPixel.trackCustom(config.fb.events.loginUnknown,null);
            }
        })
    };

    handleLoginResponse = (response:AxiosResponse<ResponsesLoginResponse>) =>{
        let user = response.data.data.user;
        if(user.email!==null && user.email!==undefined && user.email.length>0){
            _learnq.push(['identify', {
                // Change the line below to dynamically print the user's email.
                '$id' : user.unid
            }]);
            // _hsq.push(["identify",{
            //     email: user.email
            // }]);
        }

        if(this.props.updateToken !== undefined && response.data.data!==undefined && response.data.data.token!==undefined) {
            this.props.updateToken(response.data.data.token);
        }
        if(this.props.onSuccess!==null && this.props.onSuccess!==undefined){
            this.props.onSuccess(response);
        }
    };

    handleFacebookLogin = (response:MyFBInfo)=>{
        if(response.id){
            let request = {
                id: response.id,
                name: response.name,
                picture: response.picture.data.url,
                email: response.email !== null && response.email !== undefined ? response.email : null
            } as any;
            this.props.handleRequest(asApiClient.accountsApi.fBLogin(request), (response: AxiosResponse<ResponsesLoginResponse>) => {
                this.handleLoginResponse(response)
            })
        }
    };

    getSubmitMenu = (submitHandler:any) =>{
        if(this.state.submitType==="login"){
            return (
                <div>
                    <Button variant="primary" size="lg" block onClick={submitHandler}>Login</Button>
                    {/*<FacebookLogin*/}
                    {/*    version={"6.0"}*/}
                    {/*    appId={config.fb.appID}*/}
                    {/*    fields="name,email,picture"*/}
                    {/*    callback={this.handleFacebookLogin}*/}
                    {/*    cssClass="fb-login-btn"*/}
                    {/*    icon={<FaFacebook/>}*/}
                    {/*    disableMobileRedirect={true}*/}
                    {/*    textButton={"Continue with Facebook"}*/}
                    {/*/>*/}
                    <div className={"text-link"}><a href="#!"  onClick={() => this.setState({submitType: "forgot"})}>Forgot Password</a></div>
                    <div className={"text-link"}>Don't have an account? <a href="#!"  onClick={() => this.setState({submitType: "signup"})}>Create one now</a></div>
                </div>
            );
        }else if(this.state.submitType==="signup"){
            return(
                <div>
                    <Button variant="primary" size="lg" block onClick={submitHandler}>Register</Button>
                    {/*<FacebookLogin*/}
                    {/*    version={"6.0"}*/}
                    {/*    appId={config.fb.appID}*/}
                    {/*    fields="name,email,picture"*/}
                    {/*    callback={this.handleFacebookLogin}*/}
                    {/*    cssClass="fb-login-btn"*/}
                    {/*    icon={<FaFacebook/>}*/}
                    {/*    textButton={"Continue with Facebook"}*/}
                    {/*/>*/}
                    <div className={"text-link"}>Already have an account? <a href="#!" onClick={() => this.setState({submitType: "login"})}>Login</a></div>
                </div>
            );
        }else if(this.state.submitType==="forgot"){
            return(
                <div>
                    <Button variant="primary" size="lg" block onClick={submitHandler}>Submit</Button>
                    <div className={"text-link"}>Don't have an account? <a href="#!"  onClick={() => this.setState({submitType: "signup"})}>Create one now</a></div>
                    <div className={"text-link"}>Already have an account? <a href="#!" onClick={() => this.setState({submitType: "login"})}>Login</a></div>
                </div>
            );
        }
    };

    render(){
        if(this.state.submitType==="forgotEnd"){
            return(
                <div className={"forgot-end"}>
                    Please check our email to reset your password.
                </div>
            );
        }else {
            return (
                <div className={"login-form wn-form"}>
                    <Formik
                        validationSchema={this.state.submitType === "login" ? loginSchema : this.state.submitType == "signup" ? registerSchema : forgotSchema}
                        onSubmit={this.submit}
                        initialValues={{
                            email: '',
                            password: '',
                            name: '',
                            acceptsMarketing: false,
                            phoneNumber: ''
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              setFieldValue,
                              errors,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Row>
                                    {this.state.submitType === "signup" ?
                                        <Form.Group as={Col} xs="12" controlId="validationName">
                                            <Form.Label className={"sr-only"}>enter Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder={"enter name"}
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        : null}
                                    <Form.Group as={Col} xs="12" controlId="validationEmail">
                                        <Form.Label className={"sr-only"}>Enter Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder={"enter email"}
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        {this.state.submitType === "singup" ?
                                            <Form.Text className="text-muted">
                                                We'll never share your email with anyone else.
                                            </Form.Text> : null}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {this.state.submitType === "login" || this.state.submitType === "signup" ?
                                        <Form.Group as={Col} xs="12" controlId="validationPassword">
                                            <Form.Label className={"sr-only"}>Enter Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder={"enter password"}
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={!!errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group> : null}
                                    {this.state.submitType==="signup" ?
                                        <Form.Group as={Col} xs="12">
                                            <Form.Label className={"sr-only"}>Phone Number</Form.Label>
                                            <PhoneInput
                                                className={"custom-input" + (this.state.invalidPhone?" invalid":"")}
                                                placeholder="Enter phone number (Optional)"
                                                value={values.phoneNumber}
                                                onChange={(e:any)=>{this.setState({invalidPhone:false});setFieldValue("phoneNumber",e)}}/>
                                        </Form.Group>:null}
                                    {this.state.submitType==="signup" ?
                                        <Form.Group as={Col} xs="12">
                                            <Form.Check
                                                required
                                                name="acceptsMarketing"
                                                label="Keep me informed of exclusive news and offers"
                                                onChange={handleChange}
                                                checked={values.acceptsMarketing}
                                                isInvalid={!!errors.acceptsMarketing}
                                                feedback={errors.acceptsMarketing}
                                                id="validationAcceptsMarketing"
                                            />
                                        </Form.Group>:null}
                                </Form.Row>
                                {this.getSubmitMenu(handleSubmit)}
                            </Form>
                        )}
                    </Formik>
                    {this.state.submitType === "login" || this.state.submitType === "signup" ?
                        <div className={"terms"}>
                            By submitting music, rating tracks or entering any contest, sweepstakes, or promotion through this web app, the <a target="_blank" href={"http://xwave.fm/terms"}>Terms of Use</a>,
                            &nbsp;<a target="_blank" href={"http://xwave.fm/contests"}>Contest Rules</a> and/or <a target="_blank" href={"http://xwave.fm/privacy"}>Privacy Policy</a> available on this site govern. All User Contributions through this web app must comply with the Content Standards set out in the <a target="_blank" href={"http://xwave.fm/terms"}>Terms of Use</a>.
                        </div>:null
                    }
                </div>
            );
        }
    }
}

interface MyFBInfo extends ReactFacebookLoginInfo{
    picture:{
        data:{
            url: string
        }
    }
}

export default withSystemState(withApiHandler(LoginForm,ErrorHandler.TOAST));