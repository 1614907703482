import React, {Component} from 'react';
import "../../sass/common.scss";
import "../../sass/pages/LeaderboardsPage.scss";
import CuratorLeaderboard from "./CuratorLeaderboard";
import TrackLeaderboard from "./TrackLeaderboard";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {ModalEntry} from "../../pages/LandingPage";

interface LeaderboardsBaseProps {
    showArtistLeaderboard: boolean
    openModal: (modal: ModalEntry)=>void;
}

interface LeaderboardsState{

}

type LeaderboardsProps = LeaderboardsBaseProps;

class Leaderboards extends Component<LeaderboardsProps,LeaderboardsState> {

    render() {
        return (
            <div className={"leaderboards"}>
                <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                    <Tab eventKey="home" tabClassName={"border-right"} title="tastemakers">
                        <CuratorLeaderboard/>
                    </Tab>
                    <Tab eventKey="profile" title="tracks">
                        <TrackLeaderboard openModal={this.props.openModal}/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default Leaderboards