import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import "../sass/pages/LandingPage.scss";
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoginForm from "../components/misc/LoginForm";
import SubmitTrackForm from "../components/misc/SubmitTrackForm";
import Checkout from "../components/misc/Checkout";
import SubmissionRater from "../components/misc/SubmissionRater";
import ReactResizeDetector from "react-resize-detector";
import NavBar, {NavbarAction} from "../components/navigation/NavBar";
import Leaderboards from "../components/misc/Leaderboards";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import RatedSubmissions from "../components/misc/RatedSubmissions";
import CardsView from "../components/views/CardsView";
import LastCheckoutView from '../components/views/LastCheckoutView'
import UserSubmissionsView from "../components/views/UserSubmissionsView";
import MessagesView from "../components/views/MessagesView";
import ProfileView from "../components/views/ProfileView";
import { ReactComponent as MusicIcon } from '../images/music-icon.svg';
import { ReactComponent as CloseIcon } from '../images/close-icon.svg';
import ReactGA from 'react-ga';
import EditUserForm from "../components/misc/EditUserForm";
import {
    DbmodelsBannerItem,
    DbmodelsContest,
    DbmodelsSubmission,
    DbmodelsUser, EditUser, ResponsesContestForDisplayResponse,
    ResponsesLoginResponse,
    ResponsesUserResponse
} from "../api_clients/as_client/src";
import OptInForm from "../components/misc/OptInForm";
import Moment from "react-moment";
import ReactPixel from "react-facebook-pixel";
import FlagIssueForm from "../components/misc/FlagIssueForm";
import {_learnq} from "../index";
import config from "../config/config";

export type LandingPageProps = RouteComponentProps & InjectedSystemStateProps & InjectedApiHandlerProps;

interface LandingPageState {
    loading: boolean;
    showModal: boolean;
    modalQueue: ModalEntry[];
    screenWidth: number;
    rateKey: number;
    subTab: string;
    user: DbmodelsUser|null;
    landingPageKey: number;
    navTitle: string;
    currentContest: DbmodelsContest|null;
    upcomingContest: DbmodelsContest|null;
    bannerImages: DbmodelsBannerItem[];
}

export interface ModalEntry {
    title: string|React.ReactNode;
    body: React.ReactNode;
    closeHandler?: ()=>void;
}

class LandingPage extends React.Component<LandingPageProps, LandingPageState> {

    state={
        loading: false,
        showModal: false,
        modalQueue: [] as ModalEntry[],
        screenWidth: 10000,
        rateKey: 0,
        subTab:"home",
        user: null as DbmodelsUser|null,
        landingPageKey:0,
        navTitle:"",
        currentContest: null,
        upcomingContest: null,
        bannerImages: [] as DbmodelsBannerItem[],
    };

    componentDidMount(): void {
        let viewParam = this.getViewPageParam();
        if(this.props.systemState.token.length>0 && this.state.user===null){
            this.loadUser(()=>{
                this.loadViewFromParam();
            });
        }else {
            if (viewParam !== null) {
                if (this.state.user !== null) {
                    this.loadViewFromParam();
                } else {
                    this.openModal({
                        title: getLoginTitle(),
                        body: (<LoginForm onSuccess={this.loadViewFromParamAfterLogin}/>)
                    });
                }
            }
        }
        this.loadContests();
    }

    componentDidUpdate(prevProps: Readonly<LandingPageProps>, prevState: Readonly<LandingPageState>, snapshot?: any): void {

    }

    loadViewFromParamAfterLogin = () =>{
        this.setState({
            showModal: false,
            modalQueue: [],
            rateKey: this.state.rateKey+1,
        });
        this.loadViewFromParam();
    };

    loadViewFromParam = () =>{
        let viewParam = this.getViewPageParam();
        if(viewParam!==null){
            this.setState({
                subTab: viewParam
            });
        }
    };

    getViewPageParam = () =>{
        const urlParams = new URLSearchParams(this.props.location.search);
        let view = urlParams.get('view');
        if(view!==null && view.length>0){
            return view;
        }
        return null;
    };

    loadContests = () =>{
        this.props.handleRequest(asApiClient.contestsAPI.getContestsForDisplay(),(response:AxiosResponse<ResponsesContestForDisplayResponse>)=>{
            this.setState({
                currentContest: response.data.data.current,
                upcomingContest: response.data.data.upcoming
            })
        });
    };

    addedEmailToUser = () =>{
        let showModal = this.state.modalQueue.length>1;
        let newQueue = [...this.state.modalQueue];
        newQueue.shift();
        this.setState({
            showModal: showModal,
            modalQueue: newQueue,
            rateKey: this.state.rateKey+1,
        });
    };

    handlePhonePromptClose = () =>{
        let body = {
            promptedPhoneNumber: true
        }as EditUser;
        asApiClient.usersApi.editUser(this.props.systemState.token,body).then((response:AxiosResponse<ResponsesUserResponse>)=> {

        }).catch((error:any)=> {
            console.log(error)
        });
        this.handleModalClose();
    };

    loadUser = (done?:()=>void) =>{
        this.props.handleRequest(asApiClient.usersApi.getUser(this.props.systemState.token),(response:AxiosResponse<ResponsesUserResponse>)=>{
            let user = response.data.data.user;
            if(user.email!==null && user.email!==undefined && user.email.length>0){
                _learnq.push(['identify', {
                    // Change the line below to dynamically print the user's email.
                    '$id' : user.unid
                }]);
                // _hsq.push(["identify",{
                //     email: user.email
                // }]);
            }
            if(user.email===undefined || user.email===null || user.email.length<=0){
                let newQueue = [...this.state.modalQueue];
                newQueue.unshift({
                    title: getEmailNeededTitle(),
                    closeHandler: ()=>{},
                    body: <div className={"edit-user-modal"}><EditUserForm fbLogin={true} onSuccess={this.addedEmailToUser}/></div>
                } as ModalEntry);
                this.setState({
                    showModal: true,
                    modalQueue: newQueue,
                    rateKey: this.state.rateKey+1,
                    user: response.data.data.user
                },done);
            }else if(user.promptedPhoneNumber===false && (user.phoneNumber===undefined || user.phoneNumber === null || user.phoneNumber.length<=0)){
                let newQueue = [...this.state.modalQueue];
                newQueue.unshift({
                    title: getEnterPhoneNumberTitle(),
                    closeHandler: this.handlePhonePromptClose,
                    body: <div className={"edit-user-modal"}><EditUserForm fbLogin={true} onSuccess={this.addedEmailToUser}/></div>
                } as ModalEntry);
                this.setState({
                    showModal: true,
                    modalQueue: newQueue,
                    rateKey: this.state.rateKey+1,
                    user: response.data.data.user
                },done);
            }else{
                this.setState({
                    user: response.data.data.user
                },done);
            }
        });
    };

    handleLoginSuccess = (response: AxiosResponse<ResponsesLoginResponse>) =>{
        let user = response.data.data.user;
         if(user.email!==null && user.email!==undefined && user.email.length>0){
             _learnq.push(['identify', {
                 // Change the line below to dynamically print the user's email.
                 '$id' : user.unid
             }]);
             // _hsq.push(["identify",{
            //     email: user.email
            // }]);
         }
        ReactPixel.trackCustom(config.fb.events.login,null);
        ReactPixel.trackCustom("Page View","/login");
        let showModal = this.state.modalQueue.length>1;
        let newQueue = [...this.state.modalQueue];
        newQueue.shift();
        if(user.email===null || user.email===undefined || user.email.length<=0) {
            showModal = true;
            newQueue.unshift({
                title: getEmailNeededTitle(),
                closeHandler: ()=>{},
                body: <div className={"edit-user-modal"}><EditUserForm fbLogin={true} onSuccess={this.addedEmailToUser}/></div>
            } as ModalEntry);
        }
        this.setState({showModal:showModal});
        this.setState({
            showModal: showModal,
            modalQueue: newQueue,
            rateKey: this.state.rateKey+1,
            user: user
        });
    };

    handleSubmitTrackSuccess= (submission:DbmodelsSubmission) =>{
        ReactGA.pageview("/payment");
        // _hsq.push(['setPath', "/payment"]);
        // _hsq.push(['trackPageView']);
        _learnq.push(['track', 'page_view',{"page":"/payment"}]);
        ReactPixel.trackCustom("Page View","/payment");
        ReactPixel.trackCustom(config.fb.events.clickedPaidButton,null);
        let newQueue = [...this.state.modalQueue];
        newQueue.shift();
        newQueue.push({
            title: getPaymentTitle(submission.title),
            body: <Checkout onComplete={this.onPaymentComplete} submissionUnid={submission.unid as string}/>,
            closeHandler:()=>{
                this.handleModalClose();
                this.setState({
                    landingPageKey: this.shouldReloadAfterSubmissionChange()?this.state.landingPageKey+1:this.state.landingPageKey
                });
            }
        });
        this.setState({
            showModal: true,
            modalQueue: newQueue
        });
    };

    onOptInComplete = () =>{
        let newQueue = [...this.state.modalQueue];
        newQueue.shift();
        newQueue.push({
            title: getPaymentThankYouTitle(),
            body: (
                <div>
                </div>
            )
        });
        this.setState({
            showModal: true,
            modalQueue: newQueue,
            landingPageKey: this.shouldReloadAfterSubmissionChange()?this.state.landingPageKey+1:this.state.landingPageKey
        });
        ReactGA.pageview("/"+this.state.subTab);
        // _hsq.push(['setPath', "/"+this.state.subTab]);
        // _hsq.push(['trackPageView']);
        _learnq.push(['track', 'page_view',{"page":"/"+this.state.subTab}]);
        ReactPixel.trackCustom("Page View","/"+this.state.subTab);
        this.loadUser();
        ReactGA.event({
            category: config.googleAnalytics.events.contestOptin.category,
            action: config.googleAnalytics.events.contestOptin.action,
        });
        ReactPixel.trackCustom(config.fb.events.contestOptin,null);
        _learnq.push(['track', 'event', {"id":config.klaviyo.events.contestOptin}]);
        // _hsq.push(["trackEvent", {
        //     id: config.hubSpot.events.submitOptin
        // }]);
    };

    onPaymentComplete = (submissionUnid:string) =>{
        ReactGA.event({
            category: config.googleAnalytics.events.paidTrack.category,
            action: config.googleAnalytics.events.paidTrack.action,
        });
        ReactPixel.trackCustom(config.fb.events.paidTrack,null);
        _learnq.push(['track', 'event', {"id":config.klaviyo.events.paidTrack}]);
        // _hsq.push(["trackEvent", {
        //     id: config.hubSpot.events.submitStep4ID
        // }]);
        let newQueue = [...this.state.modalQueue];
        newQueue.shift();
        newQueue.push({
            title: getContestsTitle(),
            body: <OptInForm onSuccess={this.onOptInComplete} submissionUnid={submissionUnid} user={this.state.user}/>,
            closeHandler:()=>{
                this.handleModalClose();
                this.setState({
                    landingPageKey: this.shouldReloadAfterSubmissionChange()?this.state.landingPageKey+1:this.state.landingPageKey
                });
            }
        });
        this.setState({
            showModal: true,
            modalQueue: newQueue,
            landingPageKey: this.shouldReloadAfterSubmissionChange()?this.state.landingPageKey+1:this.state.landingPageKey
        });
        ReactGA.pageview("/"+this.state.subTab);
        // _hsq.push(['setPath', "/"+this.state.subTab]);
        // _hsq.push(['trackPageView']);
        _learnq.push(['track', 'page_view',{"page":"/"+this.state.subTab}]);
        ReactPixel.trackCustom("Page View","/"+this.state.subTab);
    };

    shouldReloadAfterSubmissionChange = () =>{
        return this.state.subTab==="leaderboards" || this.state.subTab==="submittedTracks";
    };

    onSubmitTrack = () =>{
        ReactGA.pageview("/submission");
        // _hsq.push(['setPath', "/submission"]);
        // _hsq.push(['trackPageView']);
        _learnq.push(['track', 'page_view',{"page":"/submission"}]);
        ReactPixel.trackCustom("Page View","/submission");
        ReactGA.event({
            category: config.googleAnalytics.events.submitTrackInterest.category,
            action: config.googleAnalytics.events.submitTrackInterest.action,
        });
        ReactPixel.trackCustom(config.fb.events.submitTrackInterest,null);
        _learnq.push(['track', 'event', {"id":config.klaviyo.events.submitTrackInterest}]);
        // _hsq.push(["trackEvent", {
        //     id: config.hubSpot.events.submitInterest
        // }]);
        if(this.props.systemState.token.length===0){
            let queue = [] as ModalEntry[];
            queue.push({
                title: getLoginTitle(),
                body: (<LoginForm onSuccess={this.handleLoginSuccess}/>)
            });
            queue.push({
                title: getSubmitTrackTitle(),
                body: <SubmitTrackForm onSuccess={this.handleSubmitTrackSuccess}/>,
            });
            this.setState({
                showModal:true,
                modalQueue: queue
            });
        }else{
            let queue = [] as ModalEntry[];
            queue.push({
                title: getSubmitTrackTitle(),
                body: <SubmitTrackForm onSuccess={this.handleSubmitTrackSuccess}/>
            });
            this.setState({
                showModal:true,
                modalQueue: queue
            });
        }
    };

    handleModalClose = () =>{
        this.props.updateSubmission({})
        this.setState({
            showModal: false,
            modalQueue: []
        })
    };

    getModal = () =>{
        let modalEntry = {
            title: "",
            body: <span>None</span>
        } as ModalEntry;
        if(this.state.modalQueue.length>0){
            modalEntry = this.state.modalQueue[0];
        }
        let hideHandler = this.handleModalClose;
        if(modalEntry.closeHandler!==null && modalEntry.closeHandler!==undefined){
            hideHandler = modalEntry.closeHandler;
        }
        return(
            <Modal backdrop={'static'} show={this.state.showModal} onHide={hideHandler} size="lg" centered>
                <Modal.Body>
                    <div className={"modal-close-btn"} onClick={hideHandler}><CloseIcon/></div>
                    <div className={"modal-title"}>{modalEntry.title}</div>
                    <div className={"modal-body-content"}>{modalEntry.body}</div>
                </Modal.Body>
            </Modal>
        );
    };

    onCheckoutSuccess=()=>{
        this.props.history.push("/");
        this.props.location.hash = "#home";
        this.setState({
            rateKey: this.state.rateKey+1,
            subTab:"home",
        });
    }

    openModal = (modalEntry: ModalEntry) =>{
        let queue = [] as ModalEntry[];
        queue.push(modalEntry);
        this.setState({
            showModal:true,
            modalQueue: queue,
        });
    };

    logOut = () =>{
        ReactGA.pageview("/logout");
        // _hsq.push(['setPath', "/logout"]);
        // _hsq.push(['trackPageView']);
        _learnq.push(['track', 'page_view',{"page":"/logout"}]);
        ReactPixel.trackCustom("Page View","/logout")
        this.props.removeToken();
        this.props.history.push("/");
        this.props.location.hash = "#home";
        this.setState({
            rateKey: this.state.rateKey+1,
            user: null,
            subTab:"home"
        });
    };

    login = () =>{
        this.openModal({
            title: getLoginTitle(),
            body: (<LoginForm onSuccess={this.handleLoginSuccess}/>)
        });
    };

    getPageContent = () =>{
        if(this.state.loading){
            return(
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        let content = null;
        let subtab = this.state.subTab;

        if(subtab==="leaderboards"){
            content = (
                <Leaderboards showArtistLeaderboard={this.state.user!==null && this.state.user.role !==undefined && this.state.user.role>0} openModal={this.openModal}/>
            );
        }else if(subtab==="home"){
            content = <SubmissionRater key={this.state.rateKey} user={this.state.user} loadUser={this.loadUser} openModal={this.openModal} closeModal={this.handleModalClose} appMessage={this.state.subTab==="home"?<>{this.getAlertEmailInvalid()}{this.getContestMessage()}</>:null}/>;
        }else if(subtab==="ratedTracks"){
            content = <RatedSubmissions openModal={this.openModal}/>
        }else if(subtab==="payments"){
            content = <CardsView/>
        }else if(subtab==="submittedTracks"){
            content = <UserSubmissionsView openModal={this.openModal} user={this.state.user as DbmodelsUser} loadUser={this.loadUser}/>
        }else if(subtab==="messages"){
            content = <MessagesView/>
        }else if(subtab==="profile"){
            content = <ProfileView loadUser={this.loadUser} openModal={this.openModal} closeModal={this.handleModalClose}/>
        }else if(subtab==='checkout')
            content = <LastCheckoutView closeModal={this.handleModalClose} openModal={this.openModal} user={this.state.user as DbmodelsUser} onCheckoutSuccess={this.onCheckoutSuccess}/>
        return(
            <div className={"content-wrapper"}>
                {content}
            </div>
        );
    };

    onScreenResize = (width:number) =>{
        this.setState({
            screenWidth: width
        });
    };

    getNavbarActions = ():NavbarAction[] =>{
        if(this.props.systemState.token.length>0){
            return[{
                title:"Home",
                id: "home",
                action: ()=>{
                    // _hsq.push(['setPath', "/home"]);
                    // _hsq.push(['trackPageView']);
                    _learnq.push(['track', 'page_view',{"page":"/home"}]);
                    ReactGA.pageview("/home");
                    ReactPixel.trackCustom("Page View","/home");
                    this.setState({subTab:"home",navTitle:""});
                }
            },{
                title:"Leaderboards",
                id: "leaderboards",
                action: ()=>{
                    // _hsq.push(['setPath', "/leaderboards"]);
                    // _hsq.push(['trackPageView']);
                    _learnq.push(['track', 'page_view',{"page":"/leaderboards"}]);
                    ReactGA.pageview("/leaderboards");
                    ReactPixel.trackCustom("Page View","/leaderboards");
                    this.setState({subTab:"leaderboards",navTitle:"leaderboards"});
                }
            },{
                title:"Account",
                id: "account",
                subNavActions: [
                    {
                        title: "Profile",
                        id: "profile",
                        action: ()=>{
                            // _hsq.push(['setPath', "/profile"]);
                            // _hsq.push(['trackPageView']);
                            _learnq.push(['track', 'page_view',{"page":"/profile"}]);
                            ReactGA.pageview("/profile");
                            ReactPixel.trackCustom("Page View","/profile");
                            this.setState({subTab:"profile",navTitle:"profile"});
                        }
                    },
                    {
                        title: "Messages",
                        id: "messages",
                        action: ()=>{
                            // _hsq.push(['setPath', "/messages"]);
                            // _hsq.push(['trackPageView']);
                            _learnq.push(['track', 'page_view',{"page":"/messages"}]);
                            ReactGA.pageview("/messages");
                            ReactPixel.trackCustom("Page View","/messages");
                            this.setState({subTab:"messages",navTitle:"Messages"});
                        }
                    },
                    {
                        title: "Submitted Tracks",
                        id: "submittedTracks",
                        action: ()=>{
                            // _hsq.push(['setPath', "/submittedTracks"]);
                            // _hsq.push(['trackPageView']);
                            _learnq.push(['track', 'page_view',{"page":"/submittedTracks"}]);
                            ReactGA.pageview("/submittedTracks");
                            ReactPixel.trackCustom("Page View","/submittedTracks");
                            this.setState({subTab:"submittedTracks",navTitle:"My Tracks"});
                        }
                    },
                    {
                        title: "Rated Tracks",
                        id: "ratedTracks",
                        action: ()=>{
                            // _hsq.push(['setPath', "/ratedTracks"]);
                            // _hsq.push(['trackPageView']);
                            _learnq.push(['track', 'page_view',{"page":"/ratedTracks"}]);
                            ReactGA.pageview("/ratedTracks");
                            ReactPixel.trackCustom("Page View","/ratedTracks");
                            this.setState({subTab:"ratedTracks",navTitle:"Rated Tracks"});
                        }
                    },{
                        title: "Payments",
                        id: "payments",
                        action: ()=>{
                            // _hsq.push(['setPath', "/cards"]);
                            // _hsq.push(['trackPageView']);
                            _learnq.push(['track', 'page_view',{"page":"/payments"}]);
                            ReactGA.pageview("/payments");
                            ReactPixel.trackCustom("Page View","/payments");
                            this.setState({subTab:"payments",navTitle:"Payments"});
                        }
                    }
                ],
            },{
                title:"Log Out",
                id:"logout",
                action: ()=>{this.logOut()}
            }] as NavbarAction[];
        }else{
            return[{
                title:"Home",
                id: "home",
                action: ()=>{
                    // _hsq.push(['setPath', "/home"]);
                    // _hsq.push(['trackPageView']);
                    _learnq.push(['track', 'page_view',{"page":"/home"}]);
                    ReactGA.pageview("/home");
                    ReactPixel.trackCustom("Page View","/home");
                    this.setState({subTab:"home",navTitle:""});
                }
            },{
                title:"Leaderboards",
                id: "leaderboards",
                action: ()=>{
                    // _hsq.push(['setPath', "/leaderboards"]);
                    // _hsq.push(['trackPageView']);
                    _learnq.push(['track', 'page_view',{"page":"/leaderboards"}]);
                    ReactGA.pageview("/leaderboards");
                    ReactPixel.trackCustom("Page View","/leaderboards");
                    this.setState({subTab:"leaderboards",navTitle:"leaderboards"});
                }
            },{
                title:"Login",
                id: "login",
                action: ()=> {
                    // _hsq.push(['setPath', "/login"]);
                    // _hsq.push(['trackPageView']);
                    _learnq.push(['track', 'page_view',{"page":"/login"}]);
                    ReactGA.pageview("/login");
                    ReactPixel.trackCustom("Page View","/login");
                    this.openModal({
                        title: getLoginTitle(),
                        body: (<LoginForm onSuccess={this.handleLoginSuccess}/>)
                    });
                }
            }] as NavbarAction[];
        }
    };

    sponsorLinkClicked = () =>{
        ReactGA.event({
            category: config.googleAnalytics.events.sponsorClick.category,
            action: config.googleAnalytics.events.sponsorClick.action,
        });
        ReactPixel.trackCustom(config.fb.events.sponsorClick,null);
        _learnq.push(['track', 'event', {"id":config.klaviyo.events.sponsorClick}]);
        // _hsq.push(["trackEvent", {
        //     id: config.hubSpot.events.sponsorClick
        // }]);
    };

    getContestMessage = () =>{
        return null;
        // return(
        //     <div className={"contest-message wn-form"}>
        //         Artists! To qualify for this contest, you must have a <span className="text-link"><a href={"https://bit.ly/HWNSLANG"} onClick={()=>this.sponsorLinkClicked()} target="_blank">Slang Distribution</a></span> account.
        //     </div>
        // );
    };

    getAlertEmailInvalid = () =>{
        if(this.state.user!==null && this.state.user.emailInvalid){
            return (
                <div className={"contest-message wn-form invalid"}>
                    We were not able to confirm your email address. Please update your email under Account {'->'} Profile
                </div>
            );
        }
    };

    render(){
        let navbarActions = this.getNavbarActions();
        return(
            <div className={"landing-page"} key={this.state.landingPageKey} role="main">
                <NavBar actions={navbarActions} title={this.state.navTitle} expand={navbarActions.length<=3?true:"md"}/>
                {this.state.subTab!=="home"?<>{this.getAlertEmailInvalid()}{this.getContestMessage()}</>:null}
                <Container className={"landing-page-content"}>
                    {this.getModal()}
                    {this.getPageContent()}
                    <ReactResizeDetector refreshRate={1000} handleWidth handleHeight onResize={this.onScreenResize} />
                </Container>
                <div className={"submit-container"}>
                    <Button onClick={this.onSubmitTrack} size={"lg"} block className={"submit-track-button"}><MusicIcon className={"music-icon"}/> Submit A Track</Button>
                </div>
            </div>
        );
    }
}

export function getLoginTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                XWAVE
            </div>
            <div className={"sub-title"}>
                Help us find & reward the talent of tomorrow
            </div>
        </div>
    );
}

export function getFlagIssueDetailsTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Flagging Issues
            </div>
        </div>
    );
}

export function getFlagIssueBan(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Flagging Issues
            </div>
            <div className={"sub-title"}>
                We’re going to disable the Feedback link while our engineers take a closer look at your account.
            </div>
        </div>
    );
}

export function getSubmitTrackTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Submit A Track
            </div>
            <div className={"sub-title"}>
                By entering information below, you affirm you own this track’s copyright
            </div>
        </div>
    );
}

export function getViewRatedTrackTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                View Rated Track
            </div>
        </div>
    );
}

export function getViewTrackTitle(title:string,artistName:string){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                {title}
            </div>
            <div className={"sub-title"} style={{paddingBottom:"10px"}}>
                {artistName}
            </div>
        </div>
    );
}

export function getPaymentTitle(title:string | undefined){
    return(
        <div className={"payment-title"}>
            <div className={"modal-wn-title"}>
                <div className={"main-title"}>
                    PAYMENT
                </div>
                <div className={"sub-title"}>
                    submit your track {title}
                </div>
            </div>
        </div>
    );
}

export function getOopsTitle(subtitle:string){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Ooops!
            </div>
            <div className={"sub-title"}>
                {subtitle}
            </div>
        </div>
    );
}

export function getContestsTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                CONTESTS
            </div>
        </div>
    );
}

export function getPaymentThankYouTitle(){
    return(
        <div className={"payment-title"}>
            <div className={"modal-wn-title"}>
                <div className={"main-title"}>
                    Thank you for submitting to xwave
                </div>
                <div className={"sub-title"}>
                    Your track will begin receiving ratings soon.
                </div>
            </div>
        </div>
    );
}

export function getOptInThankYou(){
    return(
        <div className={"payment-title"}>
            <div className={"modal-wn-title"}>
                <div className={"main-title"}>
                    Thank you for updating your contest preferences!
                </div>
            </div>
        </div>
    );
}

export function getResultsTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Results
            </div>
        </div>
    );
}

export function getEmailNeededTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Email Needed
            </div>
            <div className={"sub-title"}>
                An email is needed in order for us to be able to contact you in case of any issues with your account.
            </div>
        </div>
    );
}

export function getEnterPhoneNumberTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Phone Number
            </div>
            <div className={"sub-title"}>
                You haven't added a phone number to your account. Update now to help us better contact you about your contest submissions, special offers and exclusive news!
            </div>
        </div>
    );
}

export function getEditAccountTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Edit Account
            </div>
        </div>
    );
}

export default withSystemState(withRouter(withApiHandler(LandingPage,ErrorHandler.TOAST)));