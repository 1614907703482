import React, {Component} from 'react';
import "../../sass/common.scss";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import "../../sass/components/SubmissionRater.scss";
import SubmissionDisplay from "./SubmissionDisplay";
import {getFlagIssueBan, getFlagIssueDetailsTitle, getLoginTitle, ModalEntry} from "../../pages/LandingPage";
import LoginForm from "./LoginForm";
// @ts-ignore
import ScrollToBottom,{useScrollToEnd} from 'react-scroll-to-bottom';
import * as _ from "lodash";
import {
    DbmodelsBannerItem,
    DbmodelsSubmissionDetailed, DbmodelsUser,
    ResponsesBannerResponse,
    ResponsesBaseResponse,
    ResponsesCommentResponse, ResponsesFlaggedSubmissionResponse,
    ResponsesRatingResponse,
    ResponsesSubmissionResponse,
    ResponsesSubmissionWithArtistAndRatingResponse
} from "../../api_clients/as_client/src";
import Banner from "./Banner";
import FlagIssueForm from "./FlagIssueForm";
import Spinner from "react-bootstrap/Spinner";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import {_learnq} from "../../index";
import config from "../../config/config";

interface SubmissionRaterBaseProps{
    openModal: (modal: ModalEntry)=>void;
    closeModal: ()=>void;
    appMessage?: React.ReactNode;
    loadUser: ()=>void;
    user: DbmodelsUser | null;
}

interface SubmissionRaterState{
    loading: boolean;
    submissions: DbmodelsSubmissionDetailed[];
    noMoreSubmissions: boolean;
    playedSeconds: number;
    width: number,
    height: number,
    paddingBottom: number;
    bannerImages: DbmodelsBannerItem[];
    scrollType: string;
}

type SubmissionRaterProps = SubmissionRaterBaseProps & InjectedSystemStateProps & InjectedApiHandlerProps;

class SubmissionRater extends Component<SubmissionRaterProps,SubmissionRaterState> {
    state = {
        loading: false,
        submissions: [] as DbmodelsSubmissionDetailed[],
        playedSeconds: 0,
        noMoreSubmissions: false,
        width: 0,
        height: 0,
        paddingBottom:0,
        bannerImages: [],
        scrollType: "top"
    };

    componentDidUpdate () {

    }

    componentDidMount(): void {
        this.loadBanner();
        this.loadNextSubmissionToRate();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = ()=> {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    loadBanner = () =>{
        this.props.handleRequest(asApiClient.bannersApi.getBanner(),(response:AxiosResponse<ResponsesBannerResponse>)=>{
            if(response.data.data.banner!==null) {
                this.setState({bannerImages:response.data.data.banner});
            }
        });
    };

    loadNextSubmissionToRate = () =>{
        this.props.handleRequest(asApiClient.queuesApi.getSubmissionToRate(this.props.systemState.token),(response:AxiosResponse<ResponsesSubmissionWithArtistAndRatingResponse>)=>{
            if(response.data.data!==undefined && response.data.data.submission!==null) {
                let newSubmissions = [...this.state.submissions] as DbmodelsSubmissionDetailed[];
                let newSub = {...response.data.data.submission} as DbmodelsSubmissionDetailed;
                newSubmissions.push(newSub);
                this.setState({
                    submissions: newSubmissions,
                    playedSeconds: 0,
                    noMoreSubmissions: false
                })
            }else{
                this.setState({
                    noMoreSubmissions: true
                });
            }
        },undefined,()=>{
            this.setState({
                loading:false
            });
        });
    };

    onRate = _.throttle((rating: number) =>{
        if(this.props.systemState.token.length>0){
            ReactGA.event({
                category: config.googleAnalytics.events.trackRated.category,
                action: config.googleAnalytics.events.trackRated.action,
            });
            ReactPixel.trackCustom(config.fb.events.trackRated,null);
            _learnq.push(['track', 'event', {"id":config.klaviyo.events.trackRated}]);
            // _hsq.push(["trackEvent", {
            //     id: config.hubSpot.events.trackRated
            // }]);
            let lastSubmission = this.state.submissions[this.state.submissions.length-1];
            this.setState({
                loading: true
            });
            let requestData = {
                score: rating
            } as any;
            this.props.handleRequest(asApiClient.submissionsApi.createRatingForSubmission(lastSubmission.unid as string,this.props.systemState.token, requestData), (response: AxiosResponse<ResponsesRatingResponse>) => {
                if(response.data.data!==undefined) {
                    let submissions = [...this.state.submissions];
                    let lastSubCopy = {...lastSubmission};
                    lastSubCopy.rating = response.data.data.rating;
                    submissions[submissions.length - 1] = lastSubCopy;
                    this.setState({
                        submissions: submissions
                    }, () => {
                        this.loadNextSubmissionToRate();
                    });
                }
            }, undefined, () => {
                this.setState({
                    loading: false
                });
            });
        }else{
            if(this.props.openModal!==null && this.props.openModal!==undefined){
                this.props.openModal({
                    title: getLoginTitle(),
                    body: (<LoginForm onSuccess={()=>{this.props.closeModal();this.onRate(rating)}}/>)
                });
            }
        }
    },2000,{leading:true,trailing:false});

    onFlag = _.throttle(() =>{
        ReactGA.event({
            category: config.googleAnalytics.events.trackReportNotWorking.category,
            action: config.googleAnalytics.events.trackReportNotWorking.action,
        });
        ReactPixel.trackCustom(config.fb.events.trackReportNotWorking,null);
        _learnq.push(['track', 'event',{"id": config.klaviyo.events.trackReportNotWorking}]);
        // _hsq.push(["trackEvent", {
        //     id: config.hubSpot.events.trackFlagged
        // }]);
        if(this.props.systemState.token.length>0){
            let lastSubmission = this.state.submissions[this.state.submissions.length-1];
            this.setState({
                loading: true
            });
            this.props.handleRequest(asApiClient.submissionsApi.flagSubmission(lastSubmission.unid as string,this.props.systemState.token), (response: AxiosResponse<ResponsesFlaggedSubmissionResponse>) => {
                if(response.data.data.status==="banned"){
                    ReactGA.event({
                        category: config.googleAnalytics.events.trackReportBan.category,
                        action: config.googleAnalytics.events.trackReportBan.action,
                    });
                    ReactPixel.trackCustom(config.fb.events.trackReportBan,null);
                    _learnq.push(['track', 'event', {"id":config.klaviyo.events.trackReportBan}]);
                    // _hsq.push(["trackEvent", {
                    //     id: config.hubSpot.events.flagBanned
                    // }]);
                    this.props.openModal({
                        title: getFlagIssueBan(),
                        body: (<div/>)
                    });
                    this.props.loadUser();
                }
                if(response.data.data.status==="warned"){
                    ReactGA.event({
                        category: config.googleAnalytics.events.trackReportDetails.category,
                        action: config.googleAnalytics.events.trackReportDetails.action,
                    });
                    ReactPixel.trackCustom(config.fb.events.trackReportDetails,null);
                    _learnq.push(['track', 'event', {"id":config.klaviyo.events.trackReportDetails}]);
                    // _hsq.push(["trackEvent", {
                    //     id: config.hubSpot.events.flagDetailsRequested
                    // }]);
                    this.props.openModal({
                        title: getFlagIssueDetailsTitle(),
                        closeHandler: ()=>{},
                        body: (<FlagIssueForm onSuccess={()=>this.props.closeModal()}/>)
                    });
                }
                let submissions = [...this.state.submissions];
                submissions.pop();
                this.setState({
                    submissions:submissions
                },()=>{
                    this.loadNextSubmissionToRate();
                });
            }, undefined, () => {
                this.setState({
                    loading: false
                });
            });
        }else{
            if(this.props.openModal!==null && this.props.openModal!==undefined){
                this.props.openModal({
                    title: getLoginTitle(),
                    body: (<LoginForm onSuccess={()=>{this.props.closeModal();this.onFlag()}}/>)
                });
            }
        }
    },2000,{leading:true,trailing:false});

    onComment = (formData:any, submission:DbmodelsSubmissionDetailed) =>{
        ReactGA.event({
            category: config.googleAnalytics.events.trackComment.category,
            action: config.googleAnalytics.events.trackComment.action,
        });
        ReactPixel.trackCustom(config.fb.events.trackComment,null);
        _learnq.push(['track', 'event', {"id":config.klaviyo.events.trackComment}]);
        // _hsq.push(["trackEvent", {
        //     id: config.hubSpot.events.trackComment
        // }]);
        let request = formData;
        this.props.handleRequest(asApiClient.submissionsApi.createCommentForSubmission(submission.unid as string,this.props.systemState.token,request),(response:AxiosResponse<ResponsesCommentResponse>)=>{
            if(response.data.data!==undefined) {
                let newSubmissions = [...this.state.submissions];
                for (let x = 0; x < newSubmissions.length; x++) {
                    if (newSubmissions[x].unid === submission.unid) {
                        let copySub = {...newSubmissions[x]};
                        copySub.comment = response.data.data.comment;
                        newSubmissions[x] = copySub
                    }
                }
                this.setState({
                    submissions: newSubmissions
                });
            }
        });
    };

    onPlay = (lastSubmission:boolean) =>{
        this.setState({scrollType:"bottom"},()=>{
            if(lastSubmission) {
                let jumpToBottom = document.getElementsByClassName("jump-to-bottom");
                if (jumpToBottom && jumpToBottom.length > 0) {
                    let elem = jumpToBottom.item(0) as any;
                    if (elem !== null) {
                        elem.click();
                    }
                }
            }
        });
    };

    render() {
        let hasBanner = this.state.bannerImages.length>0;
        return (
            <>
                <ScrollToBottom mode={this.state.scrollType} className={"submission-rater"} id={"sub-rater"} followButtonClassName={"jump-to-bottom"}>
                    <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        text='Loading...'
                    >
                    {this.props.appMessage}
                    {hasBanner?<Banner bannerImages={this.state.bannerImages}/>:null}
                    {this.state.submissions.map((submission:DbmodelsSubmissionDetailed, index:number)=>{
                        let lastSubmission = false;
                        if(index>0 && index==this.state.submissions.length-1){
                            lastSubmission = true;
                        }
                        let onRate = this.onRate as any;
                        if(index<this.state.submissions.length-1){
                            onRate = null;
                        }
                        return(
                            <SubmissionDisplay key={"submission-"+submission.unid} flagDisabled={this.props.user!=null && this.props.user.flagBanned} onPlay={()=>this.onPlay(lastSubmission)} submission={submission} onRate={onRate} onFlag={this.onFlag} onComment={this.onComment} onContinuousScroll={true} lastSubmission={lastSubmission}/>
                        );
                    })}
                    {!this.state.noMoreSubmissions?null:<div className={"nothing-more"}>No More To Rate For Now</div>}
                    </LoadingOverlay>
                </ScrollToBottom>
            </>
        );
    }
}
export default withSystemState(withApiHandler(SubmissionRater,ErrorHandler.TOAST));