import React from 'react';
import '../sass/common.scss';
import '../sass/pages/AdminHomePage.scss';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import {RouteComponentProps, withRouter} from "react-router";
import {MiscCuratorLeaderboardEntry} from "../api_clients/as_client/src";
import {getPosition} from "../components/misc/CuratorLeaderboard";
import NavBar, {NavbarAction} from "../components/navigation/NavBar";
import ProblemSubmissionsPage from "./ProblemSubmissionsPage";
import AdminsLeaderBoardsPage from "./AdminsLeaderboardsPage";
import AdminsFlagIssueDetailsPage from "./AdminsFlagIssueDetailsPage";
import AdminsContestsPage from "./AdminsContestsPage";
import {ModalEntry} from "./LandingPage";
import Modal from "react-bootstrap/Modal";
import {ReactComponent as CloseIcon} from "../images/close-icon.svg";

interface AdminHomePageBaseProps{

}

type AdminHomePageProps = AdminHomePageBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps;

interface AdminHomePageState{
    page: string;
    navTitle: string;
    showModal: boolean;
    modalQueue: ModalEntry[];
}

class AdminHomePage extends React.Component<AdminHomePageProps, AdminHomePageState> {
    state={
        page: 'home',
        navTitle: 'Admin Portal',
        showModal: false,
        modalQueue: [] as ModalEntry[],
    };

    goTo = (page:string) =>{
        this.props.history.push(page);
    };

    getNavbarActions = ():NavbarAction[] =>{
        return[{
            title:"Home",
            id: "home",
            action: ()=>{
                this.setState({page:"home",navTitle:"Admin Portal"})
            }
        },{
            title:"Tracks With Problems",
            id: "problemSubmissions",
            action: ()=>{
                this.setState({page:"problemSubmissions",navTitle:"Tracks With Problems"})
            }
        },{
            title:"Leaderboards",
            id: "leaderboards",
            action: ()=>{
                this.setState({page:"leaderboards",navTitle:"Leaderboards"})
            }
        },{
            title:"Flag Issues From Tastemaker",
            id: "flagIssues",
            action: ()=>{
                this.setState({page:"flagIssues",navTitle:"Flag Issues"})
            }
        },{
            title:"Contests",
            id: "contests",
            action: ()=>{
                this.setState({page:"contests",navTitle:"Contests"})
            }
        },{
            title:"Logout",
            id: "logout",
            action: ()=>{
                this.props.removeAdminToken();
                this.props.history.push("/admins");
            }
        }] as NavbarAction[];
    };

    getHomeContent = () =>{
        return (
            <div className={"wn-list"}>
                <div className={"item"} onClick={()=>this.setState({page:"problemSubmissions",navTitle:"Tracks With Problems"})}>
                    <div>Tracks That Are Flagged or Disabled</div>
                </div>
                <div className={"item"} onClick={()=>this.setState({page:"leaderboards",navTitle:"Leaderboards"})}>
                    <div>Leaderboards</div>
                </div>
                <div className={"item"} onClick={()=>this.setState({page:"flagIssues",navTitle:"Flag Issues"})}>
                    <div>Flag Issues From Tastemaker</div>
                </div>
                <div className={"item"} onClick={()=>this.setState({page:"contests",navTitle:"Contests"})}>
                    <div>Contests</div>
                </div>
            </div>
        );
    };

    openModal = (modalEntry: ModalEntry) =>{
        let queue = [] as ModalEntry[];
        queue.push(modalEntry);
        this.setState({
            showModal:true,
            modalQueue: queue,
        });
    };

    handleModalClose = () =>{
        this.setState({
            showModal: false,
            modalQueue: []
        })
    };

    getModal = () =>{
        let modalEntry = {
            title: "",
            body: <span>None</span>
        } as ModalEntry;
        if(this.state.modalQueue.length>0){
            modalEntry = this.state.modalQueue[0];
        }
        let hideHandler = this.handleModalClose;
        if(modalEntry.closeHandler!==null && modalEntry.closeHandler!==undefined){
            hideHandler = modalEntry.closeHandler;
        }
        return(
            <Modal backdrop={'static'} show={this.state.showModal} onHide={hideHandler} size="lg" centered>
                <Modal.Body>
                    <div className={"modal-close-btn"} onClick={hideHandler}><CloseIcon/></div>
                    <div className={"modal-title"}>{modalEntry.title}</div>
                    <div className={"modal-body-content"}>{modalEntry.body}</div>
                </Modal.Body>
            </Modal>
        );
    };


    getPageContent = () =>{
        switch (this.state.page) {
            case "home":
                return this.getHomeContent();
            case "problemSubmissions":
                return <ProblemSubmissionsPage closeModal={this.handleModalClose} openModal={this.openModal}/>;
            case "leaderboards":
                return <AdminsLeaderBoardsPage/>;
            case "flagIssues":
                return <AdminsFlagIssueDetailsPage/>;
            case "contests":
                return <AdminsContestsPage closeModal={this.handleModalClose} openModal={this.openModal}/>;
        }
        return null;
    };

    render(){
        let navbarActions = this.getNavbarActions();
        return(
            <div className={"admin-home"}>
                <NavBar actions={navbarActions} title={this.state.navTitle} expand={navbarActions.length<=3?true:"md"}/>
                {this.getModal()}
                {this.getPageContent()}
            </div>
        );
    }
}

export function getContestTitle(edit:boolean){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                {edit?"Edit":"Add"} Contest
            </div>
        </div>
    );
}

export function getProblemSubmissionTitle(){
    return(
        <div className={"modal-wn-title"}>
            <div className={"main-title"}>
                Problem Submission
            </div>
        </div>
    );
}

export default withSystemState(withRouter(withApiHandler(AdminHomePage,ErrorHandler.TOAST)));