import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import '../sass/components/AdminContestsPage.scss';
import '../sass/common.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    AddContest,
    DbmodelsContest,
    ResponsesContestResponse,
    ResponsesContestsResponse
} from "../api_clients/as_client/src";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import Button from "react-bootstrap/Button";
import {ModalEntry} from "./LandingPage";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {Formik, FormikValues} from "formik";
import * as yup from "yup";
import {getContestTitle} from "./AdminHomePage";
import moment from "moment";
import {toast} from "react-toastify";
import Jumbotron from "react-bootstrap/Jumbotron";
let DateTime = require('react-datetime');

export type AdminContestsPageProps = AdminsContestsPageBaseProps & RouteComponentProps & InjectedSystemStateProps & InjectedApiHandlerProps;

interface AdminsContestsPageBaseProps {
    openModal: (modal: ModalEntry)=>void;
    closeModal: ()=>void;
}

interface AdminContestsPageState {
    loading: boolean;
    contests: DbmodelsContest[];
}

class AdminContestsPage extends React.Component<AdminContestsPageProps,AdminContestsPageState> {

    state={
        loading:false,
        contests: [] as DbmodelsContest[]
    };

    componentDidMount(): void {
        this.loadContests();
    }

    loadContests = () =>{
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.adminsApi.getAdminContests(this.props.systemState.adminToken),(response:AxiosResponse<ResponsesContestsResponse>)=>{
            if(response.data.data.contests!==null){
                this.setState({contests:response.data.data.contests})
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    openContest = (contest?:DbmodelsContest|null) =>{
        this.props.openModal({
            title: getContestTitle(contest!==null&&contest!==undefined),
            body: <AddEditContest submit={this.saveContest} contest={contest}/>
        })
    };

    saveContest = (formData:any, contest?: DbmodelsContest|null, done?:()=>void) =>{
        let formStartTime = moment(formData.startTime);
        let formEndTime = moment(formData.endTime);
        if(!formStartTime.isValid()){
            toast.error("Invalid Start Time",{position: toast.POSITION.TOP_CENTER});
            return;
        }
        if(!formEndTime.isValid()){
            toast.error("Invalid End Time",{position: toast.POSITION.TOP_CENTER});
            return;
        }
        let startDateTime = formStartTime.utc(false).format();
        let endDateTime = formEndTime.utc(false).format();
        let data = {
            name: formData.name,
            type: formData.type,
            startTime: startDateTime,
            endTime: endDateTime
        } as AddContest;
        if(data.type==="artist"){
            let formSubmitDeadline = moment(formData.submitDeadline);
            if(!formSubmitDeadline.isValid()){
                toast.error("Invalid Submit Deadline Time",{position: toast.POSITION.TOP_CENTER});
                return;
            }
            data["submitDeadline"] = formSubmitDeadline.utc(false).format();
        }else{
            data["submitDeadline"] = endDateTime
        }
        this.setState({loading:true});
        if(contest!==null && contest!==undefined){
            this.props.handleRequest(asApiClient.adminsApi.editAdminContest(contest.unid as string,this.props.systemState.adminToken,data),(response:AxiosResponse<ResponsesContestResponse>)=>{
                this.props.closeModal();
                this.loadContests();
            },undefined,()=>{
                this.setState({loading:false});
                if(done!==undefined) {
                    done();
                }
            })
        }else{
            this.props.handleRequest(asApiClient.adminsApi.addAdminContests(this.props.systemState.adminToken,data),(response:AxiosResponse<ResponsesContestResponse>)=>{
                this.props.closeModal();
                this.loadContests();
            },undefined,()=>{
                this.setState({loading:false});
                if(done!==undefined) {
                    done();
                }
            })
        }
    };

    render() {
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"admin-contests"}>
                    <div className={"notes"}>
                        <div className={"center"}>
                            Note that Leaderboards will reset when contests start or end in the following manner:
                        </div>
                        <ul>
                            <li>Artist Contest Starts -> Track and Curator Leaderboard Reset</li>
                            <li>Curator Contest Start -> Only Curator Leaderboard Resets</li>
                            <li>Contest Ends -> Leaderboard Reverts to All Time</li>
                        </ul>
                    </div>

                    <div className={"button-row"}>
                        <Button onClick={()=>this.openContest(null)}>Add Contest</Button>
                    </div>
                    <Table responsive striped bordered hover variant="dark">
                        <thead>
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Submit Deadline</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.contests.map((entry:DbmodelsContest,index:number)=>{
                            return(
                                <tr key={"contest-entry-"+index} onClick={()=>this.openContest(entry)}>
                                    <td>{entry.type}</td>
                                    <td>{entry.name}</td>
                                    <td><Moment local format="MM/DD/YYYY hh:mm a">{entry.startTime}</Moment></td>
                                    <td><Moment local format="MM/DD/YYYY hh:mm a">{entry.endTime}</Moment></td>
                                    <td>{entry.type==="artist"?<Moment local format="MM/DD/YYYY hh:mm a">{entry.submitDeadline}</Moment>:null}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>
        );
    }
}

interface AddEditContestProps {
    contest?: DbmodelsContest|null;
    submit: (formData:any, contest?: DbmodelsContest|null, done?:()=>void)=>void;
    loading?: boolean;
}

interface AddEditContestState {
    loading:boolean;
}

const addEditSchema = yup.object({
    name: yup.string().required(),
    startTime: yup.string().required(),
    endTime: yup.string().required(),
    submitDeadline: yup.string().when('type', {
        is: "artist",
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
    type: yup.string().required()
});

class AddEditContest extends React.Component<AddEditContestProps,AddEditContestState> {

    state={
        loading: false
    };

    getInitialValues = ()=>{
        if(this.props.contest!==null && this.props.contest!==undefined){
            return {
                name: this.props.contest.name,
                startTime: this.props.contest.startTime,
                endTime: this.props.contest.endTime,
                submitDeadline: this.props.contest.submitDeadline,
                type: this.props.contest.type
            }
        }else{
            return{
                name: '',
                startTime: '',
                endTime: '',
                submitDeadline: '',
                type: 'artist'
            }
        }
    };

    submit = (formData:any) =>{
        this.setState({loading:true});
        this.props.submit(formData,this.props.contest,()=>this.setState({loading:false}))
    };

    render() {
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"wn-form add-edit-form"}>
                <Formik
                    validationSchema={addEditSchema}
                    onSubmit={this.submit}
                    initialValues={this.getInitialValues()}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control as="select" onChange={handleChange} name="type" value={values.type}>
                                        <option value={"artist"}>Artist</option>
                                        <option value={"curator"}>Tastemaker</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder={"enter name"}
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationLink">
                                    <Form.Label>Start Time</Form.Label>
                                    <DateTime inputProps={{name:"startTime",className:"form-control"+(!!errors.startTime?" is-invalid":"")}} value={values.startTime} onChange={(value:string) => setFieldValue("startTime",value)}/>
                                    <Form.Control.Feedback type="invalid" style={(!!errors.startTime?{display:"block"}:{})}>
                                        {errors.startTime}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationLink">
                                    <Form.Label>End Time</Form.Label>
                                    <DateTime inputProps={{name:"endTime",className:"form-control"+(!!errors.endTime?" is-invalid":"")}} value={values.endTime} onChange={(value:string) => setFieldValue("endTime",value)}/>
                                    <Form.Control.Feedback type="invalid" style={(!!errors.endTime?{display:"block"}:{})}>
                                        {errors.endTime}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {values.type=="artist"?<Form.Group as={Col} xs="6" controlId="validationLink">
                                    <Form.Label>Submission Deadline Time</Form.Label>
                                    <DateTime inputProps={{name:"submitDeadline",className:"form-control"+(!!errors.submitDeadline?" is-invalid":"")}} value={values.submitDeadline} onChange={(value:string) => setFieldValue("submitDeadline",value)}/>
                                    <Form.Control.Feedback type="invalid" style={(!!errors.submitDeadline?{display:"block"}:{})}>
                                        {errors.submitDeadline}
                                    </Form.Control.Feedback>
                                </Form.Group>:null}
                            </Form.Row>
                            <Button size={"sm"} onClick={()=>handleSubmit()}>Save</Button>
                        </Form>
                    )}
                </Formik>
                </div>
            </LoadingOverlay>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(AdminContestsPage,ErrorHandler.TOAST)));