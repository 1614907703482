import * as React from "react";
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from "./pages/App";
import {createStore} from "redux";
import {rootReducer} from "./store";
import {Provider} from "react-redux";
import {updateAdminToken, updateToken} from "./store/system/actions";
import config from "./config/config";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import asApiClient from "./api_clients/as_client/ASApiClient";
import * as queryString from "querystring";
import {AxiosResponse} from "axios";
import {ResponsesBaseResponse} from "./api_clients/as_client/src";

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init(config.fb.pixelID, undefined, options);

ReactGA.initialize(config.googleAnalytics.id);
ReactGA.plugin.require("ecommerce",{ path: '/log', debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);

// @ts-ignore
//export var _hsq = window._hsq || [];

// @ts-ignore
export var _learnq = window._learnq || [];

// const tagManagerArgs = {
//     gtmId: config.googleGMT.id
// };
// TagManager.initialize(tagManagerArgs);

const store = createStore(rootReducer);

const utmParams = queryString.parse(window.location.search.substring(1));
if("utm_source" in utmParams){
    localStorage.setItem("utmParams", JSON.stringify(utmParams));
}

const token = localStorage.getItem('token');
if(token){
    store.dispatch(updateToken(token));
    let utmParams = localStorage.getItem("utmParams");
    if(utmParams){
        asApiClient.usersApi.trackUTM(token,{utmParams:utmParams,url:window.location.href,action: "cookie-login"}).then((response:AxiosResponse<ResponsesBaseResponse>)=> {

        }).catch((error:any)=> {
            console.log("could not track utm",error);
        });
    }
}

const adminToken = localStorage.getItem('adminToken');
if(adminToken){
    store.dispatch(updateAdminToken(adminToken));
}

// FB.init({
//     appId: config.fb.appID,
//     version          : 'v6.0'
// });
ReactPixel.pageView();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
