let local = {
    stripe:{
        key: "pk_test_51HSqDhDDq2je9oAYmEuThUhtvgR3l6V47aJSUCmeiPaS3mf2Pz966ZFmM7ajjCMph3rsUW3sVmGNXMQeEmuOUebl00aXVWqMPQ"
    },
    asApi:{
        endpoint: "https://l9vywc6tq3.execute-api.us-east-2.amazonaws.com/dev"
    },
    fb:{
        appID: "",
        pixelID: "",
        events:{
            login: "Log In",
            loginCurator: "Log In - Curator",
            loginArtist: "Log In - Artist",
            loginUnknown: "Log In - Unknown",
            register: "Registration",
            contestOptin: "Submit Track - Contest Opt In",
            clickedPaidButton: "Submit a Track - Pay BTN",
            paidTrack: "Submit Track - Paid",
            submitTrackInterest: "Submit Track - Interest",
            submitTrackDetails: "Submit Track - Details",
            sponsorClick: "Sponsor Click",
            paymentSelected: "Submit Track - CC",
            trackRated: "Track Rated",
            trackReportNotWorking: "Track Reported - Not Working",
            trackReportBan: "Track Reported - User Disabled",
            trackReportDetails: "Track Reported - Details Requested",
            trackComment: "Track Commented"
        }
    },
    secondsBeforeRating: 2,
    googleAnalytics:{
        id: "",
        events:{
            login:{
                category: "Login",
                action: "Login"
            },
            register:{
                category: "Registration",
                action: "Registration"
            },
            contestOptin:{
                category: "Contest",
                action: "Submit Track - Contest Opt In"
            },
            paidTrack:{
                category: "SubmitTrack",
                action: "Submit Track - Paid"
            },
            submitTrackInterest:{
                category: "SubmitTrack",
                action: "Submit Track - Interest"
            },
            sponsorClick:{
                category: "Sponsor",
                action: "Sponsor Click"
            },
            paymentSelected:{
                category: "SubmitTrack",
                action: "Submit Track - CC"
            },
            trackRated:{
                category: "Rating",
                action: "Track Rated"
            },
            trackReportNotWorking:{
                category: "Rating",
                action: "Track Reported - Not Working"
            },
            trackReportBan:{
                category: "Rating",
                action: "Track Reported - User Disabled"
            },
            trackReportDetails:{
                category: "Rating",
                action: "Track Reported - Details Requested"
            },
            trackComment:{
                category: "Rating",
                action: "Track Commented"
            },
            submitTrackDetails:{
                category: "SubmitTrack",
                action: "Submit Track - Details"
            }
        }
    },
    googleGMT:{
        id: ""
    },
    klaviyo:{
        events:{
            login: "Log In",
            loginCurator: "Log In - Curator",
            loginArtist: "Log In - Artist",
            loginUnknown: "Log In - Unknown",
            register: "Registration",
            contestOptin: "Submit Track - Contest Opt In",
            clickedPaidButton: "Submit a Track - Pay BTN",
            paidTrack: "Submit Track - Paid",
            submitTrackInterest: "Submit Track - Interest",
            submitTrackDetails: "Submit Track - Details",
            sponsorClick: "Sponsor Click",
            paymentSelected: "Submit Track - CC",
            trackRated: "Track Rated",
            trackReportNotWorking: "Track Reported - Not Working",
            trackReportBan: "Track Reported - User Disabled",
            trackReportDetails: "Track Reported - Details Requested",
            trackComment: "Track Commented"
        }
    },
    hubSpot:{
        events:{
            login: "",
            signup: "",
            trackRated: "",
            trackFlagged: "",
            trackComment: "",
            submitStep2: "",
            submitStep4ID: "",
            flagDetailsRequested: "",
            flagBanned: "",

            submitCC: "",
            submitOptin: "",
            submitInterest: "",
            sponsorClick: ""
        }
    }
};
let dev = {
    stripe:{
        key: "pk_test_51HSqDhDDq2je9oAYmEuThUhtvgR3l6V47aJSUCmeiPaS3mf2Pz966ZFmM7ajjCMph3rsUW3sVmGNXMQeEmuOUebl00aXVWqMPQ"
    },
    asApi:{
        endpoint: "https://l9vywc6tq3.execute-api.us-east-2.amazonaws.com/dev"
    },
    fb:{
        appID: "2095660737119954",
        pixelID: "213582526881707",
        events:{
            login: "Log In",
            loginCurator: "Log In - Curator",
            loginArtist: "Log In - Artist",
            loginUnknown: "Log In - Unknown",
            register: "Registration",
            contestOptin: "Submit Track - Contest Opt In",
            clickedPaidButton: "Submit a Track - Pay BTN",
            paidTrack: "Submit Track - Paid",
            submitTrackInterest: "Submit Track - Interest",
            submitTrackDetails: "Submit Track - Details",
            sponsorClick: "Sponsor Click",
            paymentSelected: "Submit Track - CC",
            trackRated: "Track Rated",
            trackReportNotWorking: "Track Reported - Not Working",
            trackReportBan: "Track Reported - User Disabled",
            trackReportDetails: "Track Reported - Details Requested",
            trackComment: "Track Commented"
        }
    },
    secondsBeforeRating: 5,
    googleAnalytics:{
        id: "UA-179013017-1",
        events:{
            login:{
                category: "Login",
                action: "Login"
            },
            register:{
                category: "Registration",
                action: "Registration"
            },
            contestOptin:{
                category: "Contest",
                action: "Submit Track - Contest Opt In"
            },
            paidTrack:{
                category: "SubmitTrack",
                action: "Submit Track - Paid"
            },
            submitTrackInterest:{
                category: "SubmitTrack",
                action: "Submit Track - Interest"
            },
            sponsorClick:{
                category: "Sponsor",
                action: "Sponsor Click"
            },
            paymentSelected:{
                category: "SubmitTrack",
                action: "Submit Track - CC"
            },
            trackRated:{
                category: "Rating",
                action: "Track Rated"
            },
            trackReportNotWorking:{
                category: "Rating",
                action: "Track Reported - Not Working"
            },
            trackReportBan:{
                category: "Rating",
                action: "Track Reported - User Disabled"
            },
            trackReportDetails:{
                category: "Rating",
                action: "Track Reported - Details Requested"
            },
            trackComment:{
                category: "Rating",
                action: "Track Commented"
            },
            submitTrackDetails:{
                category: "SubmitTrack",
                action: "Submit Track - Details"
            }
        }
    },
    googleGMT:{
        id: ""
    },
    klaviyo:{
        events:{
            login: "Log In",
            loginCurator: "Log In - Curator",
            loginArtist: "Log In - Artist",
            loginUnknown: "Log In - Unknown",
            register: "Registration",
            contestOptin: "Submit Track - Contest Opt In",
            clickedPaidButton: "Submit a Track - Pay BTN",
            paidTrack: "Submit Track - Paid",
            submitTrackInterest: "Submit Track - Interest",
            submitTrackDetails: "Submit Track - Details",
            sponsorClick: "Sponsor Click",
            paymentSelected: "Submit Track - CC",
            trackRated: "Track Rated",
            trackReportNotWorking: "Track Reported - Not Working",
            trackReportBan: "Track Reported - User Disabled",
            trackReportDetails: "Track Reported - Details Requested",
            trackComment: "Track Commented"
        }
    },
    hubSpot:{
        events:{
            login: "",
            signup: "",
            trackRated: "",
            trackFlagged: "",
            trackComment: "",
            submitStep2: "",
            submitStep4ID: "",
            flagDetailsRequested: "",
            flagBanned: "",

            submitCC: "",
            submitOptin: "",
            submitInterest: "",
            sponsorClick: ""
        }
    }
};
let prod = {
    stripe:{
        key: "pk_live_51HSqDhDDq2je9oAYD6KwtfwZzJPgKvLAyGBBiqATe4p98LNmzR1w1vqiqLwuZHTJfI7jWMuHAjB7pC4yuHbdjart00kgZeTLS9"
    },
    asApi:{
        endpoint: "https://l9vywc6tq3.execute-api.us-east-2.amazonaws.com/prod"
    },
    fb:{
        appID: "2095660737119954",
        pixelID: "204785391219032",
        events:{
            login: "Log In",
            loginCurator: "Log In - Curator",
            loginArtist: "Log In - Artist",
            loginUnknown: "Log In - Unknown",
            register: "Registration",
            contestOptin: "Submit Track - Contest Opt In",
            clickedPaidButton: "Submit a Track - Pay BTN",
            paidTrack: "Submit Track - Paid",
            submitTrackInterest: "Submit Track - Interest",
            submitTrackDetails: "Submit Track - Details",
            sponsorClick: "Sponsor Click",
            paymentSelected: "Submit Track - CC",
            trackRated: "Track Rated",
            trackReportNotWorking: "Track Reported - Not Working",
            trackReportBan: "Track Reported - User Disabled",
            trackReportDetails: "Track Reported - Details Requested",
            trackComment: "Track Commented"
        }
    },
    secondsBeforeRating: 2,
    googleAnalytics:{
        id: "UA-185621363-2",
        events:{
            login:{
                category: "Login",
                action: "Login"
            },
            register:{
                category: "Registration",
                action: "Registration"
            },
            contestOptin:{
                category: "Contest",
                action: "Submit Track - Contest Opt In"
            },
            paidTrack:{
                category: "SubmitTrack",
                action: "Submit Track - Paid"
            },
            submitTrackInterest:{
                category: "SubmitTrack",
                action: "Submit Track - Interest"
            },
            sponsorClick:{
                category: "Sponsor",
                action: "Sponsor Click"
            },
            paymentSelected:{
                category: "SubmitTrack",
                action: "Submit Track - CC"
            },
            trackRated:{
                category: "Rating",
                action: "Track Rated"
            },
            trackReportNotWorking:{
                category: "Rating",
                action: "Track Reported - Not Working"
            },
            trackReportBan:{
                category: "Rating",
                action: "Track Reported - User Disabled"
            },
            trackReportDetails:{
                category: "Rating",
                action: "Track Reported - Details Requested"
            },
            trackComment:{
                category: "Rating",
                action: "Track Commented"
            },
            submitTrackDetails:{
                category: "SubmitTrack",
                action: "Submit Track - Details"
            }
        }
    },
    googleGMT:{
        id: ""
    },
    klaviyo:{
        events:{
            login: "Log In",
            loginCurator: "Log In - Curator",
            loginArtist: "Log In - Artist",
            loginUnknown: "Log In - Unknown",
            register: "Registration",
            contestOptin: "Submit Track - Contest Opt In",
            clickedPaidButton: "Submit a Track - Pay BTN",
            paidTrack: "Submit Track - Paid",
            submitTrackInterest: "Submit Track - Interest",
            submitTrackDetails: "Submit Track - Details",
            sponsorClick: "Sponsor Click",
            paymentSelected: "Submit Track - CC",
            trackRated: "Track Rated",
            trackReportNotWorking: "Track Reported - Not Working",
            trackReportBan: "Track Reported - User Disabled",
            trackReportDetails: "Track Reported - Details Requested",
            trackComment: "Track Commented"
        }
    },
    hubSpot:{
        events:{
            login: "",
            signup: "",
            trackRated: "",
            trackFlagged: "",
            trackComment: "",
            submitStep2: "",
            submitStep4ID: "",
            flagDetailsRequested: "",
            flagBanned: "",

            submitCC: "",
            submitOptin: "",
            submitInterest: "",
            sponsorClick: ""
        }
    }
};

const config  = () =>{
    return process.env.REACT_APP_ENV === "prod"?prod:process.env.REACT_APP_ENV === "dev"?dev:local;
};

export default config();
